import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Hero from '../assets/images/hero.png'
import Manage from '../assets/images/manage.png'
import Customer from '../assets/images/customer.png'
import User from '../assets/images/user.png'
import bulk from '../assets/images/bulk.png'
import API from '../assets/images/api.png'
import Route from '../assets/images/route.png'

const Business = () => {
    return (
        <div className='body'>

            {/* HEADER */}

            <Header />

            <div class="about-4 section-padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-4-image position-relative" data-aos="zoom-out" data-aos-duration="700">
                                <img src={Hero} alt="" />

                                <div class="about-4-image-content " data-aos="fade-right" data-aos-duration="900">
                                <p>Create, track, schedule, cancel, pay by wallet, and access orders history easily through our web dashboard.</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="heading4 no-margin-heading">
                                <small data-aos="fade-left" data-aos-duration="400" class="overline">We are here to make your business succeed</small>
                                <h2 data-aos="fade-left" data-aos-duration="600">Manage deliveries and make customers happy! Just a click away.
                                </h2>
                                <p data-aos="fade-left" data-aos-duration="800">Run will automate, aggregate and optimize your business deliveries! Let’s perfect deliveries in Nigeria with you! Deliver easy and fast!
                                </p>
                                <p data-aos="fade-left" data-aos-duration="800"> 
                                1400+ Riders Ready to Deliver: Get access to thousands of vetted riders and deliver anywhere in Lagos.
                                </p>
                                <div class="space30"></div>
                                <div class="button-group" data-aos="fade-left" data-aos-duration="900">
                                    <a class="theme-btn-11" href="https://businesses.rundeliverys.com/" target='_blank'> Get Started <span><i
                                        class="fa-solid fa-arrow-right"></i></span></a>
                                </div>
                            </div>
                            <div class="space40"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="service-area section-padding2 position-relative bg2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 m-auto">
                            <div class="heading text-center">
                                <small data-aos="fade-up" data-aos-duration="400">See Our Services</small>
                                <h2 data-aos="fade-up" data-aos-duration="800">We offer wide range <span
                                    class="heilight-right heading-underline">of services</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="400">
                            <div class="single-service">
                                <div class="service-icon">
                                    <img src={Manage} alt="" />
                                </div>
                                <div class="service-content">
                                    <h3>Easy Order Management</h3>
                                    <p>Create, track, schedule, cancel, pay by wallet, and access orders history easily through our web dashboard.
                                    </p>
                                </div>
                                <a href="https://businesses.rundeliverys.com/" target='_blank' class="theme-btn-6">Get Started <i class="fa-solid fa-arrow-right"></i></a>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="600">
                            <div class="single-service">
                                <div class="service-icon">
                                    <img src={API} alt="" />
                                </div>
                                <div class="service-content">
                                    <h3>API Integration</h3>
                                    <p>Create and cancel orders, estimate delivery charges, and receive status updates straight from your own platform.</p>
                                </div>
                                <a href="https://rundelivery.docs.apiary.io" target='_blank' class="theme-btn-6">Get Started <i class="fa-solid fa-arrow-right"></i></a>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="800">
                            <div class="single-service">
                                <div class="service-icon">
                                    <img src={User} alt="" />
                                </div>
                                <div class="service-content">
                                    <h3> Multi-User Management</h3>
                                    <p>Have multiple users managed under one single account.
                                    </p>
                                </div>
                                <a href="https://businesses.rundeliverys.com/" target='_blank' class="theme-btn-6">Get Started <i class="fa-solid fa-arrow-right"></i></a>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1000">
                            <div class="single-service">
                                <div class="service-icon">
                                    <img src={Route} alt="" />
                                </div>
                                <div class="service-content">
                                    <h3>Route Optimization</h3>
                                    <p>Reduce delivery costs by creating the best route to deliver all of your parcels.
                                    </p>
                                </div>
                                <a href="https://businesses.rundeliverys.com/" target='_blank' class="theme-btn-6">Get Started <i class="fa-solid fa-arrow-right"></i></a>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="single-service">
                                <div class="service-icon">
                                    <img src={bulk} alt="" />
                                </div>
                                <div class="service-content">
                                    <h3>Bulk Upload</h3>
                                    <p>Upload all of your customers' addresses at once.</p>
                                </div>
                                <a href="https://businesses.rundeliverys.com/" target='_blank' class="theme-btn-6">Get Started <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1400">
                            <div class="single-service">
                                <div class="service-icon">
                                    <img src={Customer} alt="" />
                                </div>
                                <div class="service-content">
                                    <h3>Customer Satisfaction</h3>
                                    <p>Leave your customers constantly impressed by fast, efficient, and quality deliveries.</p>
                                </div>
                                <a href="https://businesses.rundeliverys.com/" target='_blank' class="theme-btn-6">Get Started <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    )
}

export default Business