



import React from 'react'
import { PaystackButton } from 'react-paystack';
import { useDispatch } from 'react-redux';
import { fundWallet } from '../feature/auth/authSlice';


function PayStackPayment({ amount, user }) {

  const dispatch = useDispatch()

  const config = {
    reference: (new Date()).getTime().toString(),
    email: user.email,
    amount: amount * 100,
    publicKey: ''
  };

  const handlePaystackSuccessAction = async (reference) => {
  };


  const handlePaystackCloseAction = () => {
    console.log('closed')
  }

  const componentProps = {
    ...config,
    text: 'Fund wallet',
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return (
    <PaystackButton className='btn btn-md-size btn-light rounded-pill' {...componentProps} />
  );
}

export default PayStackPayment;