import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

const initialState = {
  user: null,
  userProfile: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

// Register user

export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      return await authService.register(user);
    } catch (error) {
      const message =
        error.response.data.errors[0] ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verify = createAsyncThunk(
  "auth/verifyAccount",
  async (user, thunkAPI) => {
    try {
      return await authService.verifyAccount(user);
    } catch (error) {
      const message =
        error.response.data.error ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      error.response.data.error || error.response.data.status ||
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);

  }
});

// Login user
export const fundWallet = createAsyncThunk("auth/wallet", async (payload, thunkAPI) => {
  try {
    return await authService.fundWallet(payload);
  } catch (error) {
    const message =
      error.response.data.error || error.response.data.status ||
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);

  }
});

// Update user
export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (user, thunkAPI) => {
    try {
      return await authService.updateUser(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add user country
export const addCountry = createAsyncThunk(
  "auth/addCountry",
  async (user, thunkAPI) => {
    try {
      return await authService.addCountry(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// UPDATE AVATAR
export const updateProfileImage = createAsyncThunk(
  "auth/updateProfileImage",
  async (user, thunkAPI) => {
    try {
      return await authService.updateProfileImage(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update PASSWORD

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (user, thunkAPI) => {
    try {
      return await authService.updatePassword(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// Forgotten password
export const forgotten = createAsyncThunk(
  "auth/forgotten",
  async (user, thunkAPI) => {
    try {
      return await authService.forgotten(user);
    } catch (error) {
      const message =
        error.response.data.error || error.response.data.status ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);

    }
  }
);

// Reset password
export const resetpassword = createAsyncThunk(
  "auth/resetpassword",
  async (user, thunkAPI) => {
    try {
      return await authService.resetpassword(user);
    } catch (error) {
      const message =
      error.response.data.error || error.response.data.status ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getuser
export const getuser = createAsyncThunk(
  "auth/getuser",
  async (user, thunkAPI) => {
    try {
      return await authService.getuser(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    logout: (state) => {
      state.user = null;
      state.userProfile = null
    }
  },
  extraReducers: (builder) => {
    builder

      // REGISTER CASE

      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.success;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // VERIFY CASE

      .addCase(verify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.message = action.payload.success;
      })
      .addCase(verify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })


      // LOGIN CASE

      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })

      // USER PROFILE

      .addCase(getuser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getuser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.userProfile = action.payload;
      })
      .addCase(getuser.rejected, (state, action) => {
      })

      // UPDATE PROFILE

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.success;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Add User Country

      .addCase(addCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.success;
      })
      .addCase(addCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // UPDATE PROFILE

      .addCase(updateProfileImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.success;
      })
      .addCase(updateProfileImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // UPDATE PASSWORD

      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // LOGOUT CASE

      // .addCase(logout.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(logout.fulfilled, (state) => {
      //   state.user = null;
      //   state.userProfile = null;
      // })
      // .addCase(logout.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.message = action.payload;
      // })

      // FORGOTTEN PASSWORD CASE
      .addCase(forgotten.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotten.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.success;
      })
      .addCase(forgotten.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // RESET PASSWORD CASE
      .addCase(resetpassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetpassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.success;
      })
      .addCase(resetpassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // FUND WALLET CASE
      .addCase(fundWallet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fundWallet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = 'Your wallet have been funded !';
      })
      .addCase(fundWallet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });

  },
});

export const { reset, logout } = authSlice.actions;
export default authSlice.reducer;
