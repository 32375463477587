import axios from '../../app/api/axios'

const API_URL = ''

// Register user

const register = async (userData) => {
  const response = await axios.post("/auth/register", userData);
  return response.data;
};

// Login user

const login = async (userData) => {
  const response = await axios.post("/auth/login", userData);
  return response.data;
};

// VERIFY ACCOUNT
const verifyAccount = async (userData) => {
  const response = await axios.post("/auth/validate/phone", userData);
  return response.data;
};

// USER PROFILE

const getuser = async (payload) => {
  const response = await axios.get('/user/profile', {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${payload}`,
    },
  });

  return response.data;
};

// UPDATE USER

const updateUser = async (payload) => {
  const response = await axios.post('/user/update/profile', payload?.values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${payload?.token}`,
    },
  });

  return response.data;
};

const addCountry = async (payload) => {
  const response = await axios.post('/user/add/country', payload, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${payload?.token}`,
    },
  });

  return response.data;
};

// UPDATE PASSWORD

const updatePassword = async (payload) => {
  const response = await axios.patch(
    API_URL + "updatepassword/" + payload.id,
    payload
  );

  return response.data;
};

// UPDATE IMAGE

const updateProfileImage = async (payload) => {
  const response = await axios.post('/user/upload/avatar', payload.image, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${payload.token}`,
    },
  });

  return response.data;
};

// FORGOTTEN PASSWORD

const forgotten = async (userData) => {
  const response = await axios.post('/auth/reset/password', userData);
  return response.data;
};

// RESET PASSWORD 

const resetpassword = async (userData) => {
  const response = await axios.post('/auth/save/password', userData);
  return response.data;
};


// FUND USER WALLET
const fundWallet = async (payload) => {
  const response = await axios.post(`verify-payment/${payload}`);
  console.log(response);
  return response.data;
};


const authService = {
  register,
  verifyAccount,
  // logout,
  login,
  forgotten,
  resetpassword,
  updateUser,
  updatePassword,
  updateProfileImage,
  getuser,
  fundWallet,
  addCountry
};

export default authService;
