import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/globals.css'
import './assets/css/style.css'
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import { store } from './app/store'

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
);

