import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ContactForm from '../components/ContactForm'

function Contact() {

    return (

        <div className='body_3'>

            {/* TOP NAV */}

            {/* <Nav /> */}

            {/* HEADER */}

            <Header />

            <ContactForm />

            <div class="map w-100 mt-5">
                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1982.0348553690328!2d3.3546509182012465!3d6.512860875967138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8c383edf7ba3%3A0x799021a6b4c013e7!2s24%20Adeniji%20Cl%2C%20Idi-Araba%2C%20Lagos%20101271%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sus!4v1735596648277!5m2!1sen!2sus" height="450" style={{ border: '0' , width: '100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            
            <Footer />

        </div>

    )
}

export default Contact