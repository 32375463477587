import React, { useState } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { BtnLoader } from '../components/BtnLoader';
import toast from 'react-hot-toast';
import axios from 'axios';

const InterState = () => {
    const [loading, setLoading] = useState(false);

    const [formState, setFormState] = useState({
        senderName: '',
        senderPhone: '',
        receiverName: '',
        receiverPhone: '',
        sendingTerminal: '',
        destination: '',
        itemValue: '',
        itemCount: '',
        itemCategory: '',
        itemDescription: '',
        deliveryNote: '',
        homePickup: "no", // Default: No
        pickupAddress: "",
        homeDelivery: "no", // Default: No
        deliveryAddress: "",
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await axios.post('https://dashboard.rundeliverys.com/api/v1/user/inter-state-order', formState)

            toast.success(response.data.message)

            setFormState({
                senderName: '',
                senderPhone: '',
                receiverName: '',
                receiverPhone: '',
                sendingTerminal: '',
                destination: '',
                itemValue: '',
                itemCount: '',
                itemCategory: '',
                itemDescription: '',
                deliveryNote: '',
                homePickup: "no",
                pickupAddress: "",
                homeDelivery: "no",
                deliveryAddress: "",
            })

        } catch (error) {
            toast.error('An error occurred, Please try again !')
        } finally {
            setLoading(false)
        }
    };

    const terminals = [
        "Lagos Terminal(s)",
        "Maraba Terminal",
        "Utako Terminal",
        "Uyo Terminal",
        "Calabar Terminal",
        "Oron Terminal",
        "Enugu Terminal",
        "Onitsha Terminal",
        "Owerri Terminal",
        "Aba Terminal",
        "Awka Terminal",
        "Abakaliki Terminal",
        "Ogoja Terminal",
        "Neni Terminal",
        "Ibadan Terminal",
        "Abeokuta Terminal",
        "Ado-Ekiti Terminal",
        "Osogbo Terminal",
        "Akure Terminal",
        "Ilorin Terminal",
        "Ilesha Terminal",
        "Ile-Ife Terminal",
        "Ogbomoso Terminal",
        "Ijebu-Ode Terminal",
        "Ijebu North Terminal (Ago-Iwoye/Odogbolu)",
        "Sagamu Terminal",
        "Warri Terminal",
        "Asaba Terminal",
        "Benin Terminal",
        "Ugheli Terminal",
        "Yenagoa Terminal"
    ];
    

    const categories = [
        'Clothes',
        'Documents',
        'Food/Drinks',
        'Medicines/Pharmaceuticals',
        'Jewelries',
        'Phones/Laptop',
        'POS/ATM Card',
        'Shoes/Bags',
        'Others',
    ];

    return (
        <div className="body_2">

            <Header />

            <div className="container m-auto row my-5">
                <div className="col-lg-10 m-auto">
                    <div className="card p-3 p-lg-4 mb-3 text-center">
                        <h6 className="fw-bold mb-3">Inter State Delivery Form</h6>
                        <p>You can now start enjoying smooth, affordable, stressless Interstate Delivery in Nigeria
                            <br />
                            Please fill in your delivery details below to proceed</p>
                    </div>

                    <form onSubmit={handleSubmit}>

                        <div className="card p-3 mb-2">
                            <div className="row">
                                <div className="col-lg-6 mb-3 mb-lg-0">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control shadow-sm"
                                            placeholder="Sender's Name"
                                            id="senderName"
                                            value={formState.senderName}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="senderName">Sender's Name</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="tel"
                                            className="form-control shadow-sm"
                                            placeholder="Sender's Phone Number"
                                            id="senderPhone"
                                            value={formState.senderPhone}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="senderPhone">Sender's Phone Number</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card p-3 mb-2">
                            <div className="row">
                                <div className="col-lg-6 mb-3 mb-lg-0">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control shadow-sm"
                                            placeholder="Receiver's Name"
                                            id="receiverName"
                                            value={formState.receiverName}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="receiverName">Receiver's Name</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="tel"
                                            className="form-control shadow-sm"
                                            placeholder="Receiver's Phone Number"
                                            id="receiverPhone"
                                            value={formState.receiverPhone}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="receiverPhone">Receiver's Phone Number</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card p-3 mb-2">
                            <div className="row">
                                <div className="col-lg-6 mb-3 mb-lg-0">
                                    <div className="form-floating">
                                        <select
                                            className="form-control shadow-sm"
                                            id="sendingTerminal"
                                            value={formState.sendingTerminal}
                                            onChange={handleChange}
                                            required>
                                            <option value="" selected disabled>Select Sending Terminal</option>
                                            {terminals.map((terminal, index) => (
                                                <option key={index} value={terminal}>
                                                    {terminal}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="sendingTerminal">Run Dispatch/Sending Terminal
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <select
                                            className="form-control shadow-sm"
                                            id="destination"
                                            value={formState.destination}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" selected disabled>Select Destination Terminal</option>
                                            {terminals.map((terminal, index) => (
                                                <option key={index} value={terminal}>
                                                    {terminal}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="destination">Destination Terminal</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card p-3 mb-2">
                            <div className="row">
                                <div className="col-lg-6 mb-3 mb-lg-0">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control shadow-sm"
                                            placeholder="Value/Worth/Cost of Item"
                                            id="itemValue"
                                            value={formState.itemValue}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="itemValue">Value/Worth/Cost of Item</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="number"
                                            className="form-control shadow-sm"
                                            placeholder="Item Quantity"
                                            id="itemCount"
                                            value={formState.itemCount}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="itemCount">Item Quantity</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card p-3 mb-2">
                            <div className="row">
                                <div className="col-lg-12 mb-3">
                                    <div className="form-floating">
                                        <select
                                            className="form-control shadow-sm"
                                            id="itemCategory"
                                            value={formState.itemCategory}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" selected disabled>Item Category</option>
                                            {categories.map((category, index) => (
                                                <option key={index} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="itemCategory">Item Category</label>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-floating">
                                        <textarea
                                            className="form-control"
                                            placeholder="Item Description"
                                            id="itemDescription"
                                            style={{ height: '100px' }}
                                            value={formState.itemDescription}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                        <label htmlFor="itemDescription">Item Description</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card p-3 mb-3">
                            <h6 className="fw-bold mb-3">Home Pickup (Please note this option attracts extra charges)</h6>
                            <div className="d-flex align-items-center mb-3">
                                <label className="me-4">
                                    <input
                                        type="radio"
                                        name="homePickup"
                                        value="yes"
                                        checked={formState.homePickup === "yes"}
                                        onChange={(e) =>
                                            setFormState((prev) => ({ ...prev, homePickup: e.target.value }))
                                        }
                                        className="form-check-input"
                                    />
                                    <span className="ms-2">Yes</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="homePickup"
                                        value="no"
                                        checked={formState.homePickup === "no"}
                                        onChange={(e) =>
                                            setFormState((prev) => ({ ...prev, homePickup: e.target.value }))
                                        }
                                        className="form-check-input"
                                    />
                                    <span className="ms-2">No</span>
                                </label>
                            </div>
                            {formState.homePickup === "yes" && (
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        className="form-control shadow-sm"
                                        id="pickupAddress"
                                        placeholder="Enter Pickup Address"
                                        value={formState.pickupAddress}
                                        onChange={(e) =>
                                            setFormState((prev) => ({ ...prev, pickupAddress: e.target.value }))
                                        }
                                        required
                                    />
                                    <label htmlFor="pickupAddress">Pickup Address</label>
                                </div>
                            )}
                        </div>

                        {/* Home Delivery Section */}
                        <div className="card p-3 mb-3">
                            <h6 className="fw-bold mb-3">Home Delivery (Please note this option attracts extra charges)</h6>
                            <div className="d-flex align-items-center mb-3">
                                <label className="me-4">
                                    <input
                                        type="radio"
                                        name="homeDelivery"
                                        value="yes"
                                        checked={formState.homeDelivery === "yes"}
                                        onChange={(e) =>
                                            setFormState((prev) => ({ ...prev, homeDelivery: e.target.value }))
                                        }
                                        className="form-check-input"
                                    />
                                    <span className="ms-2">Yes</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="homeDelivery"
                                        value="no"
                                        checked={formState.homeDelivery === "no"}
                                        onChange={(e) =>
                                            setFormState((prev) => ({ ...prev, homeDelivery: e.target.value }))
                                        }
                                        className="form-check-input"
                                    />
                                    <span className="ms-2">No</span>
                                </label>
                            </div>
                            {formState.homeDelivery === "yes" && (
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        className="form-control shadow-sm"
                                        id="deliveryAddress"
                                        placeholder="Enter Delivery Address"
                                        value={formState.deliveryAddress}
                                        onChange={(e) =>
                                            setFormState((prev) => ({ ...prev, deliveryAddress: e.target.value }))
                                        }
                                        required
                                    />
                                    <label htmlFor="deliveryAddress">Delivery Address</label>
                                </div>
                            )}
                        </div>

                        <div className="card p-3 mb-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-floating">
                                        <textarea
                                            className="form-control"
                                            placeholder="Delivery Note or Any Other Comments"
                                            id="deliveryNote"
                                            style={{ height: '120px' }}
                                            value={formState.deliveryNote}
                                            onChange={handleChange}
                                        ></textarea>
                                        <label htmlFor="deliveryNote">Delivery Note or Any Other Comments</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-4">
                            <button type="submit" className="btn btn-primary btn-sm" disabled={loading}>
                                {loading ? <BtnLoader /> : 'Book Now'}
                            </button>
                            <button
                                type="reset"
                                onClick={() => setFormState({
                                    senderName: '',
                                    senderPhone: '',
                                    receiverName: '',
                                    receiverPhone: '',
                                    sendingTerminal: '',
                                    destination: '',
                                    itemValue: '',
                                    itemCount: '',
                                    itemCategory: '',
                                    itemDescription: '',
                                    deliveryNote: '',
                                    homePickup: "no",
                                    pickupAddress: "",
                                    homeDelivery: "no",
                                    deliveryAddress: "",
                                })}
                                style={{ background: 'none', border: 'none', outline: 'none', color: '#000' }}
                            >
                                Clear Form
                            </button>
                        </div>

                    </form>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default InterState;
