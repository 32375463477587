import React from 'react'
import Logo from '../../assets/images/logo/logo.png'
import { Link } from 'react-router-dom'

function Footer() {

    const year = new Date().getFullYear()

    return (

        <footer class="style-4 mt-0">
            <div class="container">
                <div class="foot">
                    <div class="row align-items-center">

                        <div class="col-lg-2">
                            <div class="logo">
                                <img width={80} src={Logo} alt="" />
                            </div>
                        </div>

                        <div class="col-lg-4 py-lg-0 py-5">

                            <span className="fw-bold"> ADDRESS: </span>

                            <p className='my-0 py-0 mt-2 fs-sm'>  <span className='fw-bold'> NG:</span> 24 Adeniji Close, Surulere, Lagos.
                            </p>

                            <p className='my-0 py-0 mt-2 fs-sm'>  <span className='fw-bold'> Rwanda :</span>1st Floor, Erica Building, Izuba City, Kigali.
                            </p>

                        </div>

                        <div class="col-lg-4 py-lg-0 pb-5">

                            <span className="fw-bold"> PHONE: </span>

                            <p className='my-0 py-0'> <a href="tel:+2348087555000"> <span className='fw-bold'> NG:</span> +234 808 7555 000 </a> </p>

                            <p className='my-0 py-0'> <a href="tel:+250790137970">  <span className='fw-bold'> RW:</span> +250790137970 </a> </p>

                        </div>

                        <div class="col-lg-2 py-lg-0 pb-5">

                            <span className="fw-bold"> EMAIL: </span>
                            <p className='my-0 py-0'> <a href="mailto:hello@rundeliverys.com">hello@rundeliverys.com</a> </p>

                        </div>

                    </div>
                </div>
                <div class="copywrite d-flex justify-content-between">
                    <small class="small">
                        © {year} Copyrights by <span class="fw-bold"> Run Technologies.</span> All Rights Reserved
                    </small>

                    <div className="d-flex align-items-center gap-3 flex-wrap">

                        <a
                            href="https://www.instagram.com/run_mobile_app/?hl=en"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline-flex align-items-center justify-content-center rounded-circle social_media_icon"
                            style={{ width: "36px", height: "36px" }}
                        >
                            <i className="fab fa-instagram"></i>
                        </a>

                        <a
                            href="https://twitter.com/run_mobile_app"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline-flex align-items-center justify-content-center rounded-circle social_media_icon"
                            style={{ width: "36px", height: "36px" }}
                        >
                            <i className="fab fa-linkedin-in"></i>
                        </a>

                        <a
                            href="https://www.facebook.com/runmobileapp/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline-flex align-items-center justify-content-center rounded-circle social_media_icon"
                            style={{ width: "36px", height: "36px" }}
                        >
                            <i className="fab fa-facebook-f"></i>
                        </a>

                        <a
                            href="https://twitter.com/run_mobile_app"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline-flex align-items-center justify-content-center rounded-circle social_media_icon"
                            style={{ width: "36px", height: "36px" }}
                        >
                            <i className="fab fa-twitter"></i>
                        </a>

                    </div>

                </div>
            </div>
        </footer>

    )
}

export default Footer